import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-family: 'Zilla Slab', serif;
  font-size: 3.6rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
  text-align: center;
  ${MEDIA.MIN_TABLET`
    font-size: 4.6rem;
    margin: 2rem 2rem 0;
  `};
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;
  text-align: center;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;

export const More = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;

export const Hidden = styled.div`
  display: none;
`;
