import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Logo from 'components/logo';
import Title from 'components/title';
import GalleryH from 'components/galleryh';
// import IOExample from 'components/io-example';
import { graphql } from 'gatsby';
import Hero from 'components/hero';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const centerItems = {
  textAlign: 'center',
};

export const LargeHeading = styled.h1`
  display: inline-block;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 1.2;
  font-style: normal;
  position: relative;
  z-index: 10;
  margin-bottom: 2rem;
  ${MEDIA.MIN_TABLET`
    font-size: 4.2rem;
  `};
  ${MEDIA.MIN_DESKTOP`
    font-size: 5.6rem;
  `};
`;

export const LogoWrapper = styled.div`
  img {
    ${MEDIA.MIN_DESKTOP`
      width: 450px;
    `};
  }
`;

const Index = ({ data }) => (
  <Layout>
    <Hero as="header">
      <Container>
        <Row>
          <Col col={12} sm={12}>
            <div style={centerItems}>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <div style={{ height: '1vh' }} />
              <LargeHeading>
                <em>A digital experience</em> team to fit your needs
              </LargeHeading>
              <Title as="h2">
                {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
              </Title>
            </div>
          </Col>
        </Row>
      </Container>
    </Hero>
    <div style={{ height: '10vh' }} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <GalleryH items={data.homeJson.gallery} />
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    {/* <IOExample /> */}
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        path
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
